<script setup lang="ts">
import { getUserGravatar } from "@/lib/helper/user/Gravatar";
import { computed } from "vue";

defineEmits(["load", "error"]);

const props = withDefaults(defineProps<{
  email?: string;
  hash?: string;
  size?: number;
  defaultImg?: string;
  rating?: string;
  alt?: string;
}>(), {
  email: "",
  hash: "",
  size: 80,
  rating: "g",
  alt: "Avatar",
});

const url = computed(() => {
  const img = getUserGravatar(props.hash, props.email, props.size, props.rating);

  return img;
});
</script>

<template>
  <img :src="url" :alt="alt" :width="size" :height="size" />
</template>
