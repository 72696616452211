import type { Navigation } from "@/interfaces/common/Navigation";
import type { NavigationByState } from "@/interfaces/common/NavigationByState";
import type { Pagination } from "@/interfaces/common/Pagination";
import type { Entity } from "@/interfaces/generic/Entity";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import { isEmpty, setIf, setOpt } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { BaseApi } from "./BaseApi";

export abstract class GenericEntityApi<T extends Entity> extends BaseApi {
  abstract getBaseUrl(entityIdentifier: EntityIdentifier): string;
  abstract parseEntity(entityFromApi: any): T;
  abstract prepareEntity(entity: any): void;
  abstract fillWithEntityIdentifierFields(
    entityFromApi: any,
    entityIdentifier: EntityIdentifier
  ): void;

  async get(args: {
    entityIdentifier: EntityIdentifier;
    page?: number;
    limit?: number;
    deleted?: number;
    params?: { [key: string]: any };
    withoutIncludes?: boolean;
  }): Promise<{
    entities: T[];
    pagination: Pagination;
    navigation: Navigation;
    navigationByState: NavigationByState;
  }> {
    const { entityIdentifier, params, withoutIncludes, ...extraArgs } = args;
    const includes = this.getDefaultInclude();
    const includeMetas = this.getDefaultIncludeMeta();

    let requestParams = {
      ...setIf(
        !isEmpty(includes) && withoutIncludes !== true,
        "include",
        includes.join(",")
      ),
      ...params,
    };

    // Added with Robert
    if (typeof params?.q === "undefined" || params.q === "") {
      requestParams = {
        ...requestParams,
        ...setIf(
          !isEmpty(includeMetas) && withoutIncludes !== true,
          "include_meta",
          includeMetas.join(",")
        ),
      };
    }

    return this.baseApiGetEntitiesPaginated({
      url: this.getBaseUrl(entityIdentifier),
      ...extraArgs,
      params: requestParams,
      parseEntity: (entityFromApi) => {
        this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
        return this.parseEntity(entityFromApi) as T;
      },
    });
  }

  async getById(
    entityIdentifier: EntityIdentifier,
    include?: string[],
    criticalFetch?: boolean,
    allowMissing: boolean = true,
  ) {
    return (await this.baseApiGetEntityById({
      url: this.getBaseUrl(entityIdentifier),
      id: entityIdentifier.id as string,
      ...setOpt(
        "include",
        (include ? include : this.getDefaultInclude()).join(",")
      ),
      parseEntity: (entityFromApi) => {
        this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
        return this.parseEntity(entityFromApi) as T;
      },
      criticalFetch,
      allowMissing
    })) as T;
  }

  async getByIds(
    ids: string[],
    entityIdentifier: EntityIdentifier,
    allowMissing: boolean = true,
  ): Promise<T[]> {
    const include = this.getDefaultInclude().join(",");

    return (
      await this.baseApiGetEntities({
        url: this.getBaseUrl(entityIdentifier),
        params: {
          ids,
          ...setIf(!isEmpty(include), "include", include),
        },
        parseEntity: (entityFromApi) => {
          this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
          return this.parseEntity(entityFromApi) as T;
        },
        allowMissing
      })
    ).data;
  }

  async post(entity: T) {
    const entityIdentifier = getEntityIdentifier(entity);
    const include = this.postDefaultInclude();

    const rawEntity = entity as any;
    this.prepareEntity(rawEntity);

    return this.baseApiPostEntity({
      url: this.getBaseUrl(entityIdentifier),
      entity: rawEntity,
      ...setOpt("include", include.length == 0 ? undefined : include.join(",")),
      parseEntity: (entityFromApi) => {
        this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
        return this.parseEntity(entityFromApi) as T;
      },
    }) as Promise<T>;
  }

  async put(entity: T) {
    const entityIdentifier = getEntityIdentifier(entity);
    const include = this.postDefaultInclude();

    const rawEntity: any = { ...entity };
    this.prepareEntity(rawEntity);

    return this.baseApiPutEntity({
      url: this.getBaseUrl(entityIdentifier),
      entity: rawEntity,
      ...setOpt("include", include.length == 0 ? undefined : include.join(",")),
      ...setOpt("containsFile", this.containsFile(rawEntity)),
      parseEntity: (entityFromApi) => {
        this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
        return this.parseEntity(entityFromApi) as T;
      },
    }) as Promise<T>;
  }

  async patch(entity: T) {
    const entityIdentifier = getEntityIdentifier(entity);

    const rawEntity: any = { ...entity };
    this.prepareEntity(rawEntity);

    return this.baseApiPatchEntity({
      url: this.getBaseUrl(entityIdentifier),
      entity: rawEntity,
      parseEntity: (entityFromApi) => {
        this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
        return this.parseEntity(entityFromApi) as T;
      },
    }) as Promise<T>;
  }

  async delete(entity: T) {
    const entityIdentifier = getEntityIdentifier(entity);

    return this.baseApiDeleteEntity({
      url: this.getBaseUrl(entityIdentifier),
      entityId: entity.id,
      parseEntity: (entityFromApi) => {
        this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
        return this.parseEntity(entityFromApi) as T;
      },
    });
  }

  async restore(entity: T) {
    const entityIdentifier = getEntityIdentifier(entity);

    return this.baseApiRestoreEntity({
      url: this.getBaseUrl(entityIdentifier),
      entityId: entity.id,
      parseEntity: (entityFromApi) => {
        this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
        return this.parseEntity(entityFromApi) as T;
      },
    });
  }

  async addTag(entity: T, tag: string) {
    const entityIdentifier = getEntityIdentifier(entity);

    return this.baseApiAddTag({
      url: this.getBaseUrl(entityIdentifier),
      entityId: entity.id,
      tag: tag,
      parseEntity: (entityFromApi) => {
        this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
        return this.parseEntity(entityFromApi) as T;
      },
    });
  }

  async removeTag(entity: T, tag: string) {
    const entityIdentifier = getEntityIdentifier(entity);

    return this.baseApiRemoveTag({
      url: this.getBaseUrl(entityIdentifier),
      entityId: entity.id,
      tag: tag,
      parseEntity: (entityFromApi) => {
        this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
        return this.parseEntity(entityFromApi) as T;
      },
    });
  }

  getDefaultInclude(): string[] {
    return [];
  }

  getDefaultIncludeMeta(): string[] {
    return ["navigation"];
  }

  postDefaultInclude(): string[] {
    return [];
  }

  containsFile(entity: any) {
    return entity?.file;
  }
}
