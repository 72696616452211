import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationProject } from "@/interfaces/organization/OrganizationProject";

class OrganizationProjectApi extends OrganizationGenericEntityApi<OrganizationProject> {
  apiIdentifier = "organization_project";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/projects`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_project")
      .asOrganizationEntity()
      .asString("title_lang_map")
      .asString("description_lang_map")
      .asId("deleted_by_user_id")
      .asIds("tag_ids")
      .build() as OrganizationProject;
  }

  getDefaultInclude() {
    return ["tag_ids"];
  }
}

export function useOrganizationProjectApi() {
  return new OrganizationProjectApi();
}
