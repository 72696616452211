import md5 from "md5";

export const getUserGravatar = (
  hash: string | undefined,
  email: string | undefined,
  size: number,
  rating: string,
) => {
  const img = [
    `https://www.gravatar.com/avatar/`,
    hash || md5((email || "").trim().toLowerCase()),
    `?s=${size}`,
    `&d=https://enlivy.com/wp-content/uploads/2021/12/favicon-150x150.png`,
    `&r=${rating}`,
  ];

  return img.join("");
};
