import { useOrganizationProjectApi } from "@/api/organization/OrganizationProjectApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationProject } from "@/interfaces/organization/OrganizationProject";
import { isEmpty } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { useGenericPusherUtils } from "@/stores/generic/GenericPusherUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { useUserStore } from "@/stores/user/User";
import { defineStore } from "pinia";

export const useOrganizationProjectsStore = defineStore(
  "OrganizationProjects",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationProject>();
    const pageCache = new OrganizationCache<OrganizationProject>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationProject>(
        "project",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions = new OrganizationGenericActions<OrganizationProject>({
      storage: storage,
      pageCache: pageCache,
      entityApi: useOrganizationProjectApi(),
      enhanceEntity: enhanceEntity,
      initializationCallback: subscribeToSocket,
      prePersist: prePersist,
    });

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationProject,
  storage: OrganizationEntityStorage<OrganizationProject>
) {
  const entityIdentifier = getEntityIdentifier(entity);
  const userStore = useUserStore();

  setEntityNameFields(entity, () => {
    const entity = storage.get(entityIdentifier);
    const lang = userStore.organization.locale;
    const title = entity?.title_lang_map[lang];

    return entity
      ? title
        ? title
        : Object.values(entity!.title_lang_map)[0]
      : "";
  });
}

function prePersist(
  entity: OrganizationProject,
  existingEntity?: OrganizationProject
) {
  if (existingEntity) {
    const remainingEntityLanguages =
      entity.title_lang_map && !isEmpty(entity.title_lang_map)
        ? Object.keys(entity.title_lang_map)
        : [];

    for (const lang in existingEntity.title_lang_map) {
      if (!remainingEntityLanguages.includes(lang)) {
        if (!entity.title_lang_map) {
          entity.title_lang_map = {};
          entity.description_lang_map = {};
        }

        entity.title_lang_map[lang] = "";
        entity.description_lang_map[lang] = "";
      }
    }
  }
}
