import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { Navigation } from "@/interfaces/common/Navigation";
import type { NavigationByState } from "@/interfaces/common/NavigationByState";
import type { Pagination } from "@/interfaces/common/Pagination";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationReportSchema } from "@/interfaces/organization/report/OrganizationReportSchema";
import { isEmpty, setIf, setOpt } from "@/lib/Object";
import { useOrganizationReportSchemaFieldApi } from "./OrganizationReportSchemaFieldApi";

class OrganizationReportSchemaApi extends OrganizationGenericEntityApi<OrganizationReportSchema> {
  apiIdentifier = "organization_report_schema";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/report-schemas`;
  }

  parseEntity(entityFromApi: any) {
    if (entityFromApi?.report_schema_fields?.data) {
      const fieldApi = useOrganizationReportSchemaFieldApi();
      const fields = entityFromApi.report_schema_fields.data;

      entityFromApi.report_schema_fields = [];
      for (const field of fields) {
        fieldApi.fillWithEntityIdentifierFields(field, {
          organizationId: entityFromApi.organization_id,
          reportSchemaId: entityFromApi.id,
        });
        entityFromApi.report_schema_fields.push(fieldApi.parseEntity(field));
      }
    }

    return new ApiResponseParser(entityFromApi, "organization_report_schema")
      .asOrganizationEntity()
      .asId("organization_user_role_id")
      .build() as OrganizationReportSchema;
  }

  async get(args: {
    entityIdentifier: EntityIdentifier;
    page?: number;
    limit?: number;
    deleted?: number;
    params?: { [key: string]: any };
    withoutIncludes?: boolean;
  }): Promise<{
    entities: OrganizationReportSchema[];
    pagination: Pagination;
    navigation: Navigation;
    navigationByState: NavigationByState;
  }> {
    const { entityIdentifier, params, withoutIncludes, ...extraArgs } = args;
    const includes = this.getDefaultInclude();
    const includeMetas = this.getDefaultIncludeMeta();

    let requestParams = {
      ...setIf(
        !isEmpty(includes) && withoutIncludes !== true,
        "include",
        includes.join(",")
      ),
      ...params,
    };

    // Added with Robert
    if (typeof params?.q === "undefined" || params.q === "") {
      requestParams = {
        ...requestParams,
        ...setIf(
          !isEmpty(includeMetas) && withoutIncludes !== true,
          "include_meta",
          includeMetas.join(",")
        ),
      };
    }

    const response = await this.baseApiGetEntitiesPaginated({
      url: this.getBaseUrl(entityIdentifier as any),
      ...extraArgs,
      params: requestParams,
      parseEntity: (entityFromApi) => {
        this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
        return this.parseEntity(entityFromApi) as OrganizationReportSchema;
      },
    });

    response.entities.forEach((entity: OrganizationReportSchema) => {
      entity.report_schema_fields.sort((a, b) => {
        return (a.order || 0) - (b.order || 0);
      });
    });

    return response;
  }

  async getById(
    entityIdentifier: EntityIdentifier,
    include?: string[],
    criticalFetch?: boolean
  ) {
    const response = (await this.baseApiGetEntityById({
      url: this.getBaseUrl(entityIdentifier as any),
      id: entityIdentifier.id as string,
      ...setOpt(
        "include",
        (include ? include : this.getDefaultInclude()).join(",")
      ),
      parseEntity: (entityFromApi) => {
        this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
        return this.parseEntity(entityFromApi) as OrganizationReportSchema;
      },
      criticalFetch,
    })) as OrganizationReportSchema;

    response.report_schema_fields.sort((a, b) => {
      return (a.order || 0) - (b.order || 0);
    });

    return response;
  }

  async getByIds(
      ids: string[],
      entityIdentifier: EntityIdentifier,
      allowMissing: boolean = true
  ): Promise<OrganizationReportSchema[]> {
    const include = this.getDefaultInclude().join(",");

    const response = (
      await this.baseApiGetEntities({
        url: this.getBaseUrl(entityIdentifier as any),
        params: {
          ids,
          ...setIf(!isEmpty(include), "include", include),
        },
        parseEntity: (entityFromApi) => {
          this.fillWithEntityIdentifierFields(entityFromApi, entityIdentifier);
          return this.parseEntity(entityFromApi) as OrganizationReportSchema;
        },
      })
    ).data;

    response.forEach((entity: OrganizationReportSchema) => {
      entity.report_schema_fields.sort((a, b) => {
        return (a.order || 0) - (b.order || 0);
      });
    });

    const fetchedIds = new Set(response.map((entity: any) => entity?.id));
    const missingIds = ids.filter((id) => !fetchedIds.has(id));

    if (allowMissing && missingIds.length) {
      const deletedPlaceholders = missingIds.map((missingId) => ({
        _entity: "organization_report_schema",
        id: missingId,
        deleted_at: new Date(),
        organization_id: entityIdentifier?.organizationId,
      } as OrganizationReportSchema));

      return [...response, ...deletedPlaceholders];
    }

    return response;
  }

  getDefaultInclude(): string[] {
    return ["report_schema_fields"];
  }
}

export function useOrganizationReportSchemaApi() {
  return new OrganizationReportSchemaApi();
}
