import { useUserApi } from "@/api/user/UserApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { Organization } from "@/interfaces/organization/Organization";
import type { User } from "@/interfaces/user/User";
import { deepClone, updateObject } from "@/lib/Object";
import {
  markPropertiesForDeletion,
  removeUnmodifiedProperties,
} from "@/lib/common/ObjectPropertiesUtils";
import { watchDeep } from "@vueuse/core";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useFrontendStore } from "../Frontend";
import { useOrganizationsStore } from "../organization/entity/Organizations";
import { getUserGravatar } from "@/lib/helper/user/Gravatar";

export const useUserStore = defineStore("user", () => {
  const api = useUserApi();
  const organizationsStore = useOrganizationsStore();

  const isLoaded = ref(false);
  const loggedUserInfo = ref({} as User);
  const activeOrganization = ref(undefined as Organization | undefined);

  const isAuthenticated = computed(
    () => loggedUserInfo.value.name != undefined
  );
  const organizationLocale = computed(
    () => activeOrganization.value?.locale || "en"
  );
  const organizationLocales = computed(
    () => activeOrganization.value?.locale_list || ["en"]
  );
  const organizationIsMultilang = computed(
    () => organizationLocales.value.length > 1
  );
  const organizationCurrency = computed(
    () => activeOrganization.value?.currency || "USD"
  );
  const organizationCurrencies = computed(
    () => activeOrganization.value?.currency_list || ["USD"]
  );
  const organizationIsMultiCurrency = computed(
    () => organizationCurrencies.value.length > 1
  );

  const hasAccess = computed(() => (shortKey: string): boolean => {
    if (!activeOrganization.value) {
      return false;
    }

    if (activeOrganization.value.user_id === loggedUserInfo.value.id) {
      return true;
    }

    const userAbilities = activeOrganization.value.user_abilities;
    const key = `organization.${shortKey}`;

    return userAbilities !== undefined && userAbilities.includes(key);
  });

  const getActiveOrganization = () => {
    return activeOrganization;
  };

  const setActiveOrganization = async (organizationId: string | undefined) => {
    if (organizationId) {
      const orgEntityIdentifier = { id: organizationId };

      if (organizationsStore.isInStore(orgEntityIdentifier)) {
        activeOrganization.value =
          organizationsStore.getEntityRef(orgEntityIdentifier)?.value;

        if (!activeOrganization.value?.membership_features) {
          activeOrganization.value!.stale = true;
          await fetchActiveOrganization(orgEntityIdentifier);
        }
      } else {
        await fetchActiveOrganization(orgEntityIdentifier);
      }
    } else {
      activeOrganization.value = undefined;
    }
  };

  const fetchActiveOrganization = async (
    entityIdentifier: EntityIdentifier
  ) => {
    const organization = await organizationsStore.getById(entityIdentifier);

    activeOrganization.value = organization.value;
    watchDeep(
      () => organization.value,
      (updatedOrganization) => {
        activeOrganization.value = updatedOrganization;
      }
    );
  };

  const updateEnlivyUserProfile = async (user: User) => {
    user = deepClone(user);
    delete user.phone_number;
    delete user.phone_number_country_code;

    markPropertiesForDeletion({
      newObject: user,
      oldObject: loggedUserInfo.value,
    });

    removeUnmodifiedProperties({
      newObject: user,
      oldObject: loggedUserInfo.value,
      ignoredKeys: ["_entity", "id"],
    });

    if (Object.keys(user).length <= 2) {
      return loggedUserInfo.value;
    }

    const updatedUser = await api.updateUser(user);

    if (updatedUser) {
      updateObject(loggedUserInfo.value, updatedUser);
    }

    const frontend = useFrontendStore();
    await frontend.updateLocale(updatedUser?.locale);

    return loggedUserInfo.value;
  };

  const updatePhone = async (
    phone_number: string,
    phone_number_country_code: string
  ) => {
    if (
      phone_number == loggedUserInfo.value.phone_number &&
      phone_number_country_code ==
        loggedUserInfo.value.phone_number_country_code
    ) {
      return loggedUserInfo.value;
    }

    const updatedUser = await api.updatePhone(
        phone_number,
        phone_number_country_code
    );

    if (updatedUser) {
      updateObject(loggedUserInfo.value, updatedUser);
    }

    return loggedUserInfo.value;
  };

  const fetchLoggedUserInfo = async () => {
    const response = (await api.getLoggedUserInfo()) as User;
    await assignLoggedUserInfo(response);

    updateChatwootInfo(response);
  };

  const updateChatwootInfo = (user: User) => {
    if (!window?.$chatwoot) {
      setTimeout(() => {
        updateChatwootInfo(user);
      }, 5000);

      return;
    }
    
    window?.$chatwoot.setUser(user.id, {
      name: user.name,
      email: user.email,
      phone_number: user.phone_number,
      country_code: user.country_code,
      avatar_url: getUserGravatar(undefined, user.email, 80, 'g')
    });

    window?.$chatwoot.setCustomAttributes({
      enlivy_user_id: user.id,
      enlivy_registration_date: user.created_at,
      enlivy_user_account_type: user.user_account_type_id,
    });
  }

  const sendEmailVerificationToken = async (id?: string) => {
    await api.sendEmailVerificationToken(id);
  };

  const checkEmailVerificationToken = async (token: string, id?: string) => {
    const user = (await api.checkEmailVerificationToken(token, id)) as User;
    await assignLoggedUserInfo(user);
  };

  const sendNewPhoneVerificationToken = async (id?: string) => {
    await api.sendNewPhoneVerificationToken(id);
  };

  const requestPhoneUnlink = async (id?: string) => {
    await api.requestPhoneUnlink(id);
  };

  const unlinkPhone = async (token: string, id?: string) => {
    const user = (await api.unlinkPhone(token, id)) as User;
    await assignLoggedUserInfo(user);
  };

  const checkPhoneVerificationToken = async (token: string, id?: string) => {
    const user = (await api.checkPhoneVerificationToken(token, id)) as User;
    await assignLoggedUserInfo(user);
  };

  const assignLoggedUserInfo = async (user: User) => {
    const storedUserInfo = JSON.parse(localStorage.getItem("user") as string);
    loggedUserInfo.value = { ...storedUserInfo, ...user };

    if (user.phone_verified_at) {
      loggedUserInfo.value.phone_verified_at = new Date(user.phone_verified_at);
    }
    if (user.phone_verification_sent_at) {
      loggedUserInfo.value.phone_verification_sent_at = new Date(
        user.phone_verification_sent_at
      );
    }
    if (user.email_verified_at) {
      loggedUserInfo.value.email_verified_at = new Date(user.email_verified_at);
    }

    useFrontendStore().locale = loggedUserInfo.value.locale;
    isLoaded.value = true;
  };

  return {
    // State
    loggedUserInfo,
    isLoaded,
    activeOrganization,

    // Getters
    isAuthenticated,
    hasAccess,
    organization: {
      locale: organizationLocale,
      locales: organizationLocales,
      isMultilang: organizationIsMultilang,
      currency: organizationCurrency,
      currencies: organizationCurrencies,
      isMultiCurrency: organizationIsMultiCurrency,
    },

    // Actions
    getActiveOrganization,
    setActiveOrganization,
    updateEnlivyUserProfile,
    updatePhone,
    fetchLoggedUserInfo,
    sendEmailVerificationToken,
    checkEmailVerificationToken,
    sendNewPhoneVerificationToken,
    requestPhoneUnlink,
    unlinkPhone,
    checkPhoneVerificationToken,
    assignLoggedUserInfo,
  };
});
