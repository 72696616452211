import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type {
  OrganizationBillingScheduleImport,
  OrganizationBillingScheduleImportSendData,
} from "@/interfaces/organization/billing-schedule/OrganizationBillingScheduleImport";
import { dateToApiFormat } from "@/lib/common/DateUtils";

class OrganizationBillingScheduleImportApi extends OrganizationGenericEntityApi<OrganizationBillingScheduleImport> {
  apiIdentifier = "organization_billing_schedule_import";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/billing-schedules/imports`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(
      entityFromApi,
      "organization_billing_schedule_import",
    )
      .asOrganizationEntity()
      .asDate("started_at")
      .asDate("completed_at")
      .asBool("success")
      .build() as OrganizationBillingScheduleImport;
  }

  prepareEntity(entity: any) {
    super.prepareEntity(entity);

    if (entity.date_from) {
      entity.date_from = dateToApiFormat(entity.date_from);
    }
  }

  async importStripeTransaction(args: {
    organizationId: string;
    data: OrganizationBillingScheduleImportSendData;
  }) {
    const response = await this.baseApiPostEntity({
      url: `${this.getBaseUrl({
        organizationId: args.organizationId,
      })}`,
      entity: args.data,
    });

    return response as OrganizationBillingScheduleImport;
  }
}

export function useOrganizationBillingScheduleImportApi() {
  return new OrganizationBillingScheduleImportApi();
}
