import ConfirmDeleteEntityDialog from "@/components/dialogs/ConfirmDeleteEntityDialog.vue";
import { preventOverflowModifier } from "@/composables/Modifiers";
import type { Entity } from "@/interfaces/generic/Entity";
import { useUserStore } from "@/stores/user/User";
import { createPopper } from "@popperjs/core";
import { unref, type Ref } from "vue";
import { openDialog as vue3OpenDialog } from "vue3-promise-dialog";
import { getStore } from "./generic/GenericUtils";

export function withPopper(
  dropdownList: HTMLUListElement,
  component: any,
  params: { width: string; top: string; left: string },
  widthOverride?: string
) {
  dropdownList.style.width = widthOverride
    ? widthOverride
    : component.$el.offsetWidth + "px";

  if (parseInt(dropdownList.style.width) < 400) {
    if (parseInt(params.left) + 400 < window.innerWidth) {
      dropdownList.style.width = "400px";
    } else {
      const width_cap = window.innerWidth - parseInt(params.left);

      if (width_cap > parseInt(dropdownList.style.width))
        dropdownList.style.width = width_cap + "px";
    }
  }

  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: "bottom-start",
    modifiers: [
      { name: "offset", options: { offset: [-14, 16] } },
      preventOverflowModifier(),
    ],
  });

  return () => popper.destroy();
}

/**
 * Sync the keys of am options array with a list of keys.
 */
export function syncOptionKeys(
  optionsLangMap: { [key: string]: { key: string; value: string }[] },
  keys: string[]
) {
  for (const locale of Object.keys(optionsLangMap)) {
    const options = optionsLangMap[locale];
    const optionKeys = options.map((item) => item.key);

    // Remove extra keys
    let index = options.length;
    const extraKeys = optionKeys.filter((key) => !keys.includes(key));
    while (index--) {
      if (extraKeys.includes(options[index].key)) {
        options.splice(index, 1);
      }
    }

    // Add missing keys
    const missingKeys = keys.filter((key) => !optionKeys.includes(key));
    for (const key of missingKeys) {
      options.splice(keys.indexOf(key), 0, { key, value: "" });
    }
  }
}

/**
 * Workaround because vue3-open-dialog doesn't yet support the new version of Vue 3.
 */
export async function openDialog(dialog: any, params?: any) {
  return await vue3OpenDialog(dialog, params);
}

export async function deleteEntity(
  entity: Entity | Ref<Entity | undefined> | undefined,
  callback?: () => any,
  timeOutMiliSeconds: number = 1,
  returnApiResponse: boolean = false
): Promise<any> {
  const rawEntity = unref(entity);
  if (!rawEntity) {
    return undefined;
  }

  const userResponseForDialog = await openDialog(ConfirmDeleteEntityDialog, {
    entity: rawEntity,
  });
  if (!userResponseForDialog) {
    return undefined;
  }

  if (callback) {
    callback();
  }

  if (timeOutMiliSeconds === 0) {
    return await getStore(rawEntity).delete(rawEntity, returnApiResponse);
  }

  setTimeout(async () => {
    await getStore(rawEntity).delete(rawEntity, returnApiResponse);
  }, timeOutMiliSeconds);
}

export function combineErrors(errors: (string[] | undefined)[]) {
  const combinedErrors = [];

  for (const error of errors) {
    if (error) {
      combinedErrors.push(...error);
    }
  }

  return combinedErrors;
}

export const getDirectionValue = (
  direction?: "inbound" | "outbound" | "any"
): "inbound" | "outbound" | undefined => {
  if (direction === "any") {
    return undefined;
  }

  return direction;
};

export const getLangValue = (options: {
  [key: string]: string | { [key: string]: string };
}) => {
  const userStore = useUserStore();

  if (options && options[userStore.organization.locale]) {
    return options[userStore.organization.locale];
  } else if (options.length) {
    return options[0];
  }

  return null;
};
