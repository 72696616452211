import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import { defineStore } from "pinia";
import { useGenericPusherUtils } from "../../generic/GenericPusherUtils";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";
import { deepClone } from "@/lib/Object";
import { ref } from "vue";
import type {
  OrganizationBillingScheduleImport,
  OrganizationBillingScheduleImportSendData,
} from "@/interfaces/organization/billing-schedule/OrganizationBillingScheduleImport";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { useOrganizationUsersStore } from "@/stores/organization/entity/OrganizationUsers";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { useOrganizationBankAccountsStore } from "@/stores/organization/entity/OrganizationBankAccounts";
import type { OrganizationBankAccount } from "@/interfaces/organization/bank/OrganizationBankAccount";
import { useOrganizationBillingScheduleImportApi } from "@/api/organization/OrganizationBillingScheduleImportApi";

export const useOrganizationBillingScheduleImportStore = defineStore(
  "organizationBillingSchedulesImports",
  () => {
    const storage =
      new OrganizationEntityStorage<OrganizationBillingScheduleImport>();
    const pageCache =
      new OrganizationCache<OrganizationBillingScheduleImport>();
    const api = useOrganizationBillingScheduleImportApi();
    const createImportState = ref<OrganizationBillingScheduleImportSendData>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationBillingScheduleImport>(
        "organization_billing_schedule_import",
        storage,
        genericActions.getById,
        pageCache.clearCache,
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions =
      new OrganizationGenericActions<OrganizationBillingScheduleImport>({
        storage: storage,
        pageCache: pageCache,
        entityApi: api,
        enhanceEntity: enhanceEntity,
        initializationCallback: subscribeToSocket,
      });

    const importStripeTransaction = async (args: {
      organizationId: string;
      data: OrganizationBillingScheduleImportSendData;
    }) => {
      return api.importStripeTransaction(args);
    };

    const getCreateImportState = () => {
      return deepClone(createImportState.value) as
        | OrganizationBillingScheduleImportSendData
        | undefined;
    };

    const setCreateImportState = (
      state: OrganizationBillingScheduleImportSendData | undefined,
    ) => {
      createImportState.value = state;
    };

    return {
      ...genericActions,
      importStripeTransaction,
      getCreateImportState,
      setCreateImportState,
    };
  },
);

export function enhanceEntity(
  entity: OrganizationBillingScheduleImport,
  storage: OrganizationEntityStorage<OrganizationBillingScheduleImport>,
) {
  const entityIdentifier = getEntityIdentifier(entity);
  const bankAccountsStore = useOrganizationBankAccountsStore();
  const userStore = useOrganizationUsersStore();

  addComputedField<OrganizationBillingScheduleImport, OrganizationBankAccount>(
    entity,
    "bankAccount",
    () =>
      storage.get(entityIdentifier)?.settings_json
        ?.organization_bank_account_id,
    async () =>
      bankAccountsStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.settings_json
          ?.organization_bank_account_id,
      }),
  );

  addComputedField<OrganizationBillingScheduleImport, OrganizationUser>(
    entity,
    "sender",
    () =>
      storage.get(entityIdentifier)?.settings_json?.organization_sender_user_id,
    async () =>
      userStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.settings_json
          ?.organization_sender_user_id,
      }),
  );

  setEntityNameFields(entity, () => storage.get(entityIdentifier)?.id);
}
