import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationContract } from "@/interfaces/organization/contract/OrganizationContract";
import type { OrganizationContractChapter } from "@/interfaces/organization/contract/OrganizationContractChapter";

class OrganizationContractApi extends OrganizationGenericEntityApi<OrganizationContract> {
  apiIdentifier = "organization_contract";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/contracts`;
  }

  parseEntity(entityFromApi: any) {
    if (entityFromApi.organization_contract_chapters?.data) {
      entityFromApi.chapters = entityFromApi.organization_contract_chapters.data
        .map((data: any) => {
          return new ApiResponseParser(data, "organization_contract_chapter")
            .asOrganizationEntity()
            .asNumber("order")
            .asId("organization_contract_id")
            .build() as OrganizationContractChapter;
        })
        .sort(
          (a: OrganizationContractChapter, b: OrganizationContractChapter) =>
            (a.order || 0) - (b.order || 0),
        );
    }

    return new ApiResponseParser(entityFromApi, "organization_contract")
      .asOrganizationEntity()
      .asId("organization_contract_id")
      .asId("organization_sender_user_id")
      .asId("organization_receiver_user_id")
      .asId("organization_contract_status_id")
      .asId("organization_file_id")
      .asId("deleted_by_user_id")
      .asBool("is_signed_by_sender_user")
      .asBool("is_signed_by_receiver_user")
      .asDate("issued_at")
      .asDate("ends_at")
      .asDate("renewed_at")
      .asDate("sender_signed_at")
      .asDate("received_signed_at")
      .build() as OrganizationContract;
  }

  getDefaultInclude() {
    return ["organization_contract_chapters"];
  }
}

export function useOrganizationContractApi() {
  return new OrganizationContractApi();
}
