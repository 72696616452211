import { useOrganizationInvoiceApi } from "@/api/organization/OrganizationInvoiceApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationContract } from "@/interfaces/organization/contract/OrganizationContract";
import type { OrganizationInvoice } from "@/interfaces/organization/invoice/OrganizationInvoice";
import type { OrganizationUser } from "@/interfaces/organization/user/OrganizationUser";
import { isEmpty } from "@/lib/Object";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import {
  addComputedField,
  setEntityNameFields,
} from "@/lib/generic/StoreUtils";
import { defineStore } from "pinia";
import { useGenericPusherUtils } from "../../generic/GenericPusherUtils";
import { OrganizationCache } from "../../generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "../../generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "../../generic/store/OrganizationGenericActions";
import { useOrganizationContractsStore } from "./OrganizationContracts";
import { useOrganizationInvoiceNetworkExchangesStore } from "./OrganizationInvoiceNetworkExchanges";
import { useOrganizationInvoicePrefixesStore } from "./OrganizationInvoicePrefixes";
import { useOrganizationUsersStore } from "./OrganizationUsers";

export const useOrganizationInvoicesStore = defineStore(
  "organizationInvoices",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationInvoice>();
    const pageCache = new OrganizationCache<OrganizationInvoice>();
    const api = useOrganizationInvoiceApi();
    const invoiceNetworkExchangesStore =
      useOrganizationInvoiceNetworkExchangesStore();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationInvoice>(
        "invoice",
        storage,
        genericActions.getById,
        pageCache.clearCache,
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions = new OrganizationGenericActions<OrganizationInvoice>({
      storage: storage,
      pageCache: pageCache,
      entityApi: api,
      enhanceEntity: enhanceEntity,
      initializationCallback: subscribeToSocket,
      preStore: preStore,
      prePersist: prePersist,
    });

    const pushInvoiceToInstitution = async (args: {
      entity: OrganizationInvoice;
      institutionId: string;
    }) => {
      const newNetworkExchange = await api.postPeppolToInstitution(args);

      invoiceNetworkExchangesStore.storeEntities([newNetworkExchange]);

      // Replace the old network exchange with the new one.
      const entityIdentifier = getEntityIdentifier(args.entity);
      const storedEntity = storage.get(entityIdentifier);
      if (storedEntity) {
        const newLastPeppolExchange =
          storedEntity.last_peppol_exchange?.filter((networkExchange) => {
            return networkExchange.institution_id !== "anaf";
          }) || [];

        newLastPeppolExchange.push(newNetworkExchange);

        storage.set(
          entityIdentifier,
          {
            ...storedEntity,
            last_peppol_exchange: newLastPeppolExchange,
          },
          subscribeToSocket,
        );
      }
    };

    return {
      ...genericActions,
      pushInvoiceToInstitution,
    };
  },
);

export function enhanceEntity(
  entity: OrganizationInvoice,
  storage: OrganizationEntityStorage<OrganizationInvoice>,
) {
  const entityIdentifier = getEntityIdentifier(entity);

  const organizationUsersStore = useOrganizationUsersStore();
  const organizationContractsStore = useOrganizationContractsStore();

  addComputedField<OrganizationInvoice, OrganizationContract>(
    entity,
    "contract",
    () => storage.get(entityIdentifier)?.organization_contract_id,
    async () =>
      organizationContractsStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_contract_id,
      })
  );
  addComputedField<OrganizationInvoice, OrganizationUser>(
    entity,
    "sender",
    () => storage.get(entityIdentifier)?.organization_sender_user_id,
    async () =>
      organizationUsersStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_sender_user_id,
      })
  );
  addComputedField<OrganizationInvoice, OrganizationUser>(
    entity,
    "receiver",
    () => storage.get(entityIdentifier)?.organization_receiver_user_id,
    async () =>
      organizationUsersStore.lazyGetById({
        organizationId: entityIdentifier.organizationId,
        id: storage.get(entityIdentifier)?.organization_receiver_user_id,
      })
  );

  setEntityNameFields(entity, () => {
    const invoice = storage.get(entityIdentifier);
    if (invoice) {
      return isEmpty(entity.invoice_prefix)
        ? invoice.number
        : invoice.invoice_prefix?.alias + invoice.number;
    }
    return undefined;
  });
}

function preStore(entity: OrganizationInvoice) {
  const prefixStore = useOrganizationInvoicePrefixesStore();
  if (!isEmpty(entity.invoice_prefix)) {
    prefixStore.storeEntities([entity.invoice_prefix]);
  }
}

function prePersist(
  entity: OrganizationInvoice,
  existingEntity?: OrganizationInvoice,
) {
  // Remove _entity from taxes
  if (entity.taxes) {
    for (const taxPackage of entity.taxes) {
      delete (taxPackage as { _entity?: any })["_entity"];
    }
  }

  // If it's an update operation...
  if (existingEntity) {
    // Remove extra line items.
    const remainingLineItemEntityIds =
      entity.line_items && !isEmpty(entity.line_items)
        ? entity.line_items.map((lineItem) => lineItem.id)
        : [];

    for (const lineItem of existingEntity.line_items) {
      if (!remainingLineItemEntityIds.includes(lineItem.id)) {
        if (!entity.line_items) {
          entity.line_items = [];
        }

        entity.line_items.push({
          id: lineItem.id,
          _deleted: true,
        } as any);
      }
    }

    const remainingEntityLanguages =
      entity.note_lang_map && !isEmpty(entity.note_lang_map)
        ? Object.keys(entity.note_lang_map)
        : [];

    for (const lang in existingEntity.note_lang_map) {
      if (!remainingEntityLanguages.includes(lang)) {
        if (!entity.note_lang_map) {
          entity.note_lang_map = {};
        }

        entity.note_lang_map[lang] = "";
      }
    }

    if (existingEntity.source == "internal") {
      // Do not send the key, in order to prevent problems with saving, as the line_items will handle the taxes.
      delete entity.taxes;
    } else {
      // Remove extra tax packages.
      const remainingTaxesEntityIds =
        entity.taxes && !isEmpty(entity.taxes)
          ? entity.taxes.map((taxPackage) => taxPackage.id)
          : [];

      for (const taxPackage of existingEntity.taxes || []) {
        if (!remainingTaxesEntityIds.includes(taxPackage.id)) {
          if (!entity.taxes) {
            entity.taxes = [];
          }

          entity.taxes.push({
            id: taxPackage.id,
            _deleted: true,
          } as any);
        }
      }
    }
  }
}
