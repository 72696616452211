<script setup lang="ts">
import { onUnmounted } from "vue";
import IconClose from "../icons/general/actions/IconClose.vue";
import AnimationLoadingStandard from "../loaders/AnimationLoadingStandard.vue";

const emit = defineEmits(["close"]);
withDefaults(
  defineProps<{
    title: string;
    width?: string;
    displayXButton?: boolean;
  }>(),
  {
    width: "500px",
    displayXButton: true
  }
);

function close() {
  emit("close");
}

onUnmounted(async () => {
  document.body.classList.remove("body-dialog-opened");
});
</script>

<template>
  <div class="dialog">
    <div class="center" @click.stop>
      <div class="dialog-content">
        <div v-if="title" class="title">
          <h2>{{ title }}</h2>
          <IconClose
            v-if="displayXButton"
            class="close"
            :width="20"
            :height="20"
            color="var(--enlivy-grey-40-color)"
            :strokeWidth="2"
            @click.prevent="close()"
          />
        </div>

        <Suspense>
          <slot></slot>

          <template #fallback>
            <div class="loader-wrapper">
              <AnimationLoadingStandard />
            </div>
          </template>
        </Suspense>

        <div v-if="$slots.actions" class="actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(31, 33, 38, 0.6);
  z-index: 1000;
}

.center {
  overflow: auto;
  max-height: 80%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--enlivy-white-100-color);
  border-radius: 10px;
  width: 80%;
  max-width: v-bind(width);
  display: flex;
  flex-direction: column;
  gap: var( --enlivy-spacing-divider-sm);

  .dialog-content {
    display: flex;
    flex-direction: column;
    gap: var(--enlivy-spacing-lg);
    position: relative;
    padding: var(--enlivy-spacing-divider-md);
    border: 1px solid var( --enlivy-grey-25-color );

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        font-weight: 700;
      }
      .close {
        cursor: pointer;
      }
    }

    .actions,
    :deep(.actions) {
      display: flex;
      gap: var(--enlivy-spacing-md);

      .secondary {
        display: inline-flex;
        align-items: center;
        margin: auto 0;
      }
    }
  }
}
</style>
