import AuthRequiredDialog from "@/components/dialogs/AuthRequiredDialog.vue";
import type { ErrorResponse } from "@/interfaces/common/error/ErrorResponse";
import { useFrontendStore } from "@/stores/Frontend";
import { AxiosError } from "axios";
import { openDialog } from "vue3-promise-dialog";

export function handleApiError(error: any, criticalFetch?: boolean, allowMissing?: boolean) {
  if (error instanceof AxiosError) {
    if (error.response) {
      if (
        error.response.status == 401 &&
        error.response.data.message == "Unauthenticated." &&
        document.location.href !== "/login"
      ) {
        // Be carreful with circular dependencies on useFrontendStore
        const frontend = useFrontendStore();
        openDialog(AuthRequiredDialog, {
          title: frontend.trans("general.error.you_are_not_authenticated"),
          text: frontend.trans(
            "general.error.your_authentication_token_has_expired"
          ),
        });
      }

      if (error.response.status == 422 && error.config?.method != "get") {
        throw { ...error.response.data, cause: error } as ErrorResponse;
      } else if (error.response.data.message) {
        throw {
          message: error.response.data.message,
          errors: {},
          cause: error,
          criticalFetch,
          allowMissing,
        } as ErrorResponse;
      } else {
        const uint8Array = new Uint8Array(error.response.data);
        const decoder = new TextDecoder("utf-8");
        const dataContentString = decoder.decode(uint8Array);
        const dataContent = JSON.parse(dataContentString);

        if (dataContent?.message) {
          throw {
            message: dataContent.message,
            errors: {},
            cause: error,
            criticalFetch,
            allowMissing,
          } as ErrorResponse;
        }
      }
    }

    throw {
      message: error.message,
      errors: {},
      cause: error,
      criticalFetch,
      allowMissing,
    } as ErrorResponse;
  }

  throw {
    message: "Unknown error",
    errors: {},
    cause: error,
    criticalFetch,
    allowMissing,
  };
}
