import { handleApiError } from "@/api/generic/ApiErrorHandler";
import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import { authHeader } from "@/composables/api/RequestHeaderGenerator";
import type { Organization } from "@/interfaces/organization/Organization";
import type { OrganizationProject } from "@/interfaces/organization/OrganizationProject";
import type { OrganizationProjectMembers } from "@/interfaces/organization/project/OrganizationProjectMembers";
import axios from "axios";

class OrganizationProjectMembersApi extends OrganizationGenericEntityApi<OrganizationProjectMembers> {
  apiIdentifier = "organization_project_members";

  getBaseUrl(args: { organizationId: string; projectId: string }) {
    return `/organizations/${args.organizationId}/projects/${args.projectId}/members`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_project_members")
      .asOrganizationEntity()
      .asId("organization_project_id")
      .asId("organization_user_id")
      .build() as OrganizationProjectMembers;
  }

  async addNewUserToProject(
    organization: Organization,
    project: OrganizationProject,
    organization_user_id: string
  ) {
    const url = this.getBaseUrl({
      organizationId: organization?.id,
      projectId: project.id,
    });

    try {
      const response = await axios.post(
        url,
        {
          organization_user_id: organization_user_id,
        },
        { headers: authHeader() }
      );

      return response.data.data as OrganizationProjectMembers;
    } catch (error) {
      handleApiError(error);
    }
  }

  async removeNewUserToProject(
    organization: Organization,
    project: OrganizationProject,
    organization_project_member_id: string
  ) {
    const url = this.getBaseUrl({
      organizationId: organization?.id,
      projectId: project.id,
    });

    try {
      const response = await axios.delete(
        `${url}/${organization_project_member_id}`,
        { headers: authHeader() }
      );

      return response.data.data as OrganizationProjectMembers;
    } catch (error) {
      handleApiError(error);
    }
  }

  async getUsers(organization: Organization, project: OrganizationProject) {
    const url = this.getBaseUrl({
      organizationId: organization.id,
      projectId: project.id,
    });

    try {
      const response = await axios.get(url, { headers: authHeader() });

      return response.data.data as OrganizationProjectMembers[];
    } catch (error) {
      handleApiError(error);
    }
  }
}

export function useOrganizationProjectMembersApi() {
  return new OrganizationProjectMembersApi();
}
